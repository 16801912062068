import { createStore as reduxCreateStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducer = (state, action) => {
	if (action.type === `INCREMENT`) {
		return Object.assign({}, state, {
			count: state.count + 1,
		});
	}
	return state;
};

const initialState = {};

const persistConfig = {
	key: 'root',
	storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);
export default () => {
	let store = reduxCreateStore(persistedReducer, initialState);
	let persistor = persistStore(store);
	return { store, persistor };
};
